<style>
.loader {
    width: 38px;
    height: 38px;
    border: 3px solid #FFF;
    border-bottom-color: #7367F0;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
    .modal-footer{
        display: none !important;
    
    }
</style>
<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalTransport" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitTransports">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <!-- <h5>Add Transports</h5> -->
                <h5 v-if="tobacco_shipment.id === null">Tobacco Loads</h5>
                <h5 v-if="tobacco_shipment.id !== null">Edit Loads</h5>


                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <!-- <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button> -->
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateFleet">
                        <b-row>
                            <b-col cols="3">
                                <b-form-group labe-for="number" label="TF Number ">
                                    <validation-provider #default="{ errors }" name="number" rules="required">
                                        <b-form-input id="number" name="number" v-model="tobacco_shipment.number"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" @keyup.enter.prevent="validateLocalTf()" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{
                                            serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            

                            <b-col cols="6">
                                <b-form-group labe-for="document_number" label="Document # ">
                                    <validation-provider #default="{ errors }" name="document_number" rules="required">
                                        <b-form-input id="document_number" name="document_number" v-model="tobacco_shipment.document_number"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.document_number">{{
                                            serverErrors.document_number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group labe-for="totalLoad" label="Load ">
                                    <validation-provider #default="{ errors }" name="totalLoad">
                                        <b-form-input id="totalLoad" name="totalLoad" :value="tickets.length"
                                            :state="errors.length > 0 ? false : null" size="sm" readonly />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.totalLoad">{{ serverErrors.totalLoad[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                       

                        </b-row>
                        <b-row>
                        
                            <b-col cols="6">
                                <b-form-group labe-for="Transporter" label="Transporter">
                                    <validation-provider #default="{ errors }" name="transporter_id" rules="required">
                                        <b-form-input id="transporter_id" name="transporter_id" v-model="tobacco_shipment.transporter_id"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.transporter_id">{{
                                            serverErrors.transporter_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                     
                            <b-col cols="6">
                                <b-form-group labe-for="Fleets" label="Fleets">
                                    <validation-provider #default="{ errors }" name="truck_id" rules="required">
                                        <b-form-input id="truck_id" name="truck_id" v-model="tobacco_shipment.truck_id"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.truck_id">{{
                                            serverErrors.truck_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                 
                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="Source" label="Source">
                                    <validation-provider #default="{ errors }" name="source_id" rules="required">
                                        <b-form-input id="source_id" name="source_id" v-model="tobacco_shipment.source_id"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.source_id">{{
                                            serverErrors.source_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>

                            <b-col cols="6">
                             
                                <b-form-group labe-for="Destination" label="Destination">
                                    <validation-provider #default="{ errors }" name="destination_id" rules="required">
                                        <b-form-input id="destination_id" name="destination_id" v-model="tobacco_shipment.destination_id"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.destination_id">{{
                                            serverErrors.destination_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                         
                            <b-col cols="6">

                                <b-form-group labe-for="Driver" label="Driver">
                                    <validation-provider #default="{ errors }" name="driver_id" rules="required">
                                        <b-form-input id="driver_id" name="driver_id" v-model="tobacco_shipment.driver_id"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.driver_id">{{
                                            serverErrors.driver_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>


                            
                            <b-col cols="6">
                                <b-form-group labe-for="licence#" label="Licence # ">
                                    <validation-provider #default="{ errors }" name="licence_number" rules="required">
                                        <b-form-input id="licence_number" name="licence_number" v-model="tobacco_shipment.licence_number"
                                            :state="errors.length > 0 ? false : null" size="sm" value="" readonly/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.licence_number">{{
                                            serverErrors.licence_number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                       
                        <b-row>
                            <!-- <b-col class="move-right">
                                <b-button size="sm" class="mb-1" variant="outline-primary"
                                    @click="addElement()">Capture ticket</b-button>

                            </b-col> -->

                            <b-col cols="4">
                                <b-form-group labe-for="Barcode" label="Barcode">
                                    <b-form-input id="share" name="barcode" v-model="tobacco_shipment.barcode" size="sm" @keyup.enter.prevent="validateBarcode()" ref="barcode"/>
                                </b-form-group>
                                
                            </b-col>
                            <b-col cols="1">
                                <b-form-group labe-for="" label="">
                                <span class="loader" v-if="loadingBar === true"></span>
                            </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group labe-for="massDispatched" label="Mass Dispatched">
                                <b-form-input id="massDispatched" name="massDispatched" v-model="tobacco_shipment.massDispatched" @keyup.enter.prevent="calculateVariance(tobacco_shipment.barcode)" size="sm" ref="massDispatched"/>
                            </b-form-group>
                             </b-col>

                             <b-col cols="3">
                                <b-form-group label-for="loadoffload" label="Is TF for Loading?">
                                <b-form-checkbox 
                                    class="custom-control-success ml-4" 
                                    name="check-button" 
                                    switch
                                    @change="handleCheckboxChange($event)" 
                                    v-model="loading">
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <b-row>

                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th width="10%">#</th>
                                        <th scope="col">Barcode</th>
                                        <th scope="col">Luggage</th>
                                        <th scope="col">P/Weight</th>
                                        <th scope="col">D/Weight</th>
                                        <th scope="col">R/Weight</th>
                                        <!-- <th scope="col">Variance</th> -->
                                        <th scope="col">Captured At</th>
                                        
                                    </tr>

                                </thead>
                                <div style="height:400px; overflow-y: auto">
                                <tbody>
                                    
                                    <tr v-for="element, index in  tickets" :key="index">
                                        <td width="10%">
                                            {{ index+1 }}
                                        </td>
                                        <td scope="row" >
                                                <!-- <b-form-input id="share" name="barcode" v-model="element.barcode" size="md" @keyup.enter.prevent="validateBarcode(index)"/> -->
                                                <label><strong>{{ element.barcode }}</strong></label>
                                        </td>
                                        <td scope="row">
                                                <!-- <b-form-input id="share" name="luggage" v-model="element.luggage" size="md" readonly/> -->
                                                <label><strong>{{ element.luggage }}</strong></label>
                                        </td>
                                        <td scope="row">
                                                <!-- <b-form-input id="share" name="massReceived" v-model="element.massReceived" size="md" readonly/> -->
                                                <label><strong>{{ element.pweight }}</strong></label>
                                        </td>

                                        <td scope="row">
                                                <!-- <b-form-input id="share" name="massDispatched" v-model="element.massDispatched" @keyup.enter.prevent="calculateVariance(index)" size="md"/> -->
                                                <label><strong>{{ parseFloat(element.dWeight).toFixed(2) }}</strong></label>
                                        </td>
                                        <td scope="row">
                                                <!-- <b-form-input id="share" name="massDispatched" v-model="element.massDispatched" @keyup.enter.prevent="calculateVariance(index)" size="md"/> -->
                                                <label><strong>{{ parseFloat(element.rWeight).toFixed(2) }}</strong></label>
                                        </td>

                                        <!-- <td scope="row">
                                                <label><strong>{{ parseFloat(element.pweight - element.weight).toFixed(2)  }}</strong></label>
                                        </td> -->
                                    
                                        <td>
                                            <label><strong>{{ element.captured_at | moment('DD/MM/YYYY H:m:s')  }}</strong></label>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                               
                                </tbody>
                            </div>
                                </table>
                        
                           

                        </b-row>



                    </b-form>
                    <!-- <pre>{{ tobacco_shipment }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

    

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>

                       
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeFleetsForm()">
                                <span class="text-nowrap">Load/Offload</span>
                            </b-button>
                            <!-- <b-button variant="info" class="ml-2" size="md" @click="requestDevice()">
                                <span class="text-nowrap">Connect to bluetooth</span>
                            </b-button> -->
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>



            <!-- Table -->
         

            <!-- Table Footer -->
            
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import tobaccoShipmentsStoreModule from '@/views/cromis/green_shipping/tobacco_shipment/tobaccoShipmentsStoreModule'
import useTobaccoShipmentsList from '@/views/cromis/green_shipping/tobacco_shipment/useTobaccoShipmentsList'
import Dropdown from 'primevue/dropdown';
import { useBluetooth } from '@vueuse/core'
import axios from '@axios'
import debounce from 'lodash/debounce';


export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, Dropdown,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalTransport = ref(null)
        const myModalTicket = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const transporters = ref([])
        const drivers = ref([])
        const transports = ref([])
        const transporterDrivers = ref([])
        const bestRoute = ref()
        const fleets = ref([])
        const trailers = ref([])
        const routes = ref([])
        const warehouses = ref([])
        const markets = ref([])
        const destinations = ref([])
        const selectedCompany = ref(null)
        const societies = ref([])
        const tikets = ref([])
        const tfDetails = ref([])
        const totalWeight = ref(0)
        const tValue = ref(0)
        const selectedDriver = ref();
        const weight = ref(null)
        const device = ref(null)
        const  allChecked=  ref(false)
        const  loadingBar=  ref(false)
        const  loading=  ref(true)
        const agreeIds = ref([])
        const selectedRows = ref([]);
        const selectedItems = ref([]);
        const tickets = ref([]);
        const transporter = ref({
            id: null,
            company_id: null,
            tin: null,
            name: null,
            vrn: null,
            busines_licence: null,
        })

        const tobacco_shipment = ref({
            id: null,
            truck_id: null,
            trailer_id:null,
            route_id: 0,
            source_id: null,
            destination_id: null,
            gender: null,
            isShortHaul: null,
            transporter_id: null,
            sourceType: 1,
            orderNumber: null,
            document_number: null,
            licence_number: null,
            number: null,
            barcode: null,
            massDispatched: null,
            portal: true,
            loading: true,
            totalLoad: 0,
            tickets: [
                {
                    luggage: null,
                    barcode: null,
                    massReceived: null,
                    massDispatched: null,
                    variance: null,
                }
            ]
        })



        const CROMIS_STORE_MODULE_NAME = 'cromis-tobacco_shipment'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, tobaccoShipmentsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            // await store.dispatch('cromis-tobacco_shipment/transporters')
            //     .then(response => {
            //         transporters.value = response.data.transporters
            //         // console.log(transporters.value)
            //     })
            //     .catch(error => {
            //         console.log('got error', error)
            //     })


            // await store.dispatch('cromis-tobacco_shipment/markets')
            //     .then(response => {
            //         markets.value = response.data.markets
            //     })
            //     .catch(error => {
            //         console.log('got error', error)
            //     })

            // await store.dispatch('cromis-tobacco_shipment/routes')
            //     .then(response => {
            //         routes.value = response.data.routes
            //         // console.log(routes.value)
            //     })
            //     .catch(error => {
            //         console.log('got error', error)
            //     })

            // await store.dispatch('cromis-tobacco_shipment/warehouses')
            //     .then(response => {
            //         warehouses.value = response.data.warehouses
            //         // console.log(warehouses.value)
            //     })
            //     .catch(error => {
            //         console.log('got error', error)
            //     })

            // await store.dispatch('cromis-tobacco_shipment/societies')
            //     .then(response => {
            //         societies.value = response.data.societies
            //         // console.log(societies.value)
            //     })
            //     .catch(error => {
            //         console.log('got error', error)
            //     })

        })

        const addElement = () => {

            tobacco_shipment.value.tickets.push({ luggage: '', barcode: '', massReceived: '', massDispatched: '', variance: '' })

            if (tobacco_shipment.value.tickets.length > 0) {
                console.log(tobacco_shipment.value.tickets)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (tobacco_shipment.value.tickets.length > 1) {
                tobacco_shipment.value.tickets.splice(index, 1)
                tobacco_shipment.value.tickets = tobacco_shipment.value.tickets
            }
        }

        const toggleCheckAll = (isChecked) => {
    if (isChecked) {
        // Assuming `records` is an array of item objects
        selectedItems.value = records.value.map(item => item.id); // Replace 'id' with your unique identifier
    } else {
        selectedItems.value = [];
    }
}

        const isItemSelected = (itemId) => {
             return selectedItems.value.includes(itemId);
        };


        const checkboxToggled = async (item, event) => {

             if (event) {
                if (!agreeIds.value.includes(item.id)) {
                    agreeIds.value.push(item.id);
                }
            } else {
                agreeIds.value = agreeIds.value.filter(id => id !== item.id);
            }

            console.log(agreeIds.value);

        };

        const generateReport = async () => {

            let payload = {
                "ids": agreeIds.value
            }
                await store.dispatch('cromis-tobacco_shipment/regenerate', payload)
                    .then(response => {
                        refetch()
                        saving.value = false

                        context.root.$swal({
                            icon: 'success',
                            text: `Regenerate File has been initialized successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        console.log(error)
                        if (error.response.status === 422) {
                            serverErrors.value = error.response.data.errors
                        }
                        else {
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
        }


        const handleCheckboxChange = async (event) => {
                tobacco_shipment.value.loading = event
                }

        const validateLocalTf = async () => {
            let found = false
            let data = {}
            // console.log(tobacco_shipment.value.number)
            tickets.value = []
            tobacco_shipment.value = {
                                    id: null,
                                    truck_id: null,
                                    driver_id:null,
                                    source_id: null,
                                    destination_id: null,
                                    transporter_id: null,
                                    licence_number:null,
                                    number: tobacco_shipment.value.number,
                                    document_number: null,
                                    portal: true,
                                    barcode: null,
                                    massDispatched: null,
                                    loading: true,
                                    totalLoad: null,
                                    tickets: [
                                        {
                                            luggage: null,
                                            barcode: null,
                                            massReceived: null,
                                            massDispatched: null,
                                            variance: null,
                                        }
                                    ]
                                }


            await store.dispatch('cromis-tobacco_shipment/validateTf', { number: tobacco_shipment.value.number })
                    .then(response => {
                        data = response.data.transports[0]
                        let truck = ''
                        console.log(data)

                        if(data){
                            if(data.fleets.length <= 1){
                            truck = data.fleets[0].plateNumber
                        }else{
                            truck = data.fleets[0].plateNumber+'/'+data.fleets[1].plateNumber
                        }
                        tobacco_shipment.value = {
                                    id: data.id,
                                    truck_id: truck,
                                    driver_id:data.drivers.fullName,
                                    source_id: data.routes.sourceName+'('+data.routes.sourceCode+')',
                                    destination_id: data.routes.destinationName+'('+data.routes.destinationCode+')',
                                    transporter_id: data.transporter? data.transporter.name : '',
                                    licence_number:data.drivers.licence,
                                    number: data.number,
                                    document_number: data.tfNumber,
                                    portal: true,
                                    barcode: null,
                                    massDispatched: null,
                                    loading: true,
                                    totalLoad: data.totalLoad,
                                    tickets: [
                                        {
                                            luggage: null,
                                            barcode: null,
                                            massReceived: null,
                                            massDispatched: null,
                                            variance: null,
                                        }
                                    ]
                                }
                        }
                                context.refs['barcode'].focus()
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

                    //tf db loads
                    if(data){
                        await store.dispatch('cromis-tobacco_shipment/transportLoad', { id: data.id })
                    .then(response => {
                        tickets.value = response.data.tickets
                         })
                    .catch(error => {
                        console.log('got error', error)
                    })
                    }
        }

        const validateBarcode = async (index) => {

            context.refs['massDispatched'].focus()
        }

        const calculateVariance = async (barcode) => {
            console.log(tobacco_shipment.value.tickets)


            // tobacco_shipment.value.tickets.forEach(ele => {
            //     if(ele.barcode === barcode){
            //         ele.massDispatched = tobacco_shipment.value.massDispatched
            //         ele.variance = parseFloat(ele.massReceived) - parseFloat(tobacco_shipment.value.massDispatched)
            //         parseFloat(ele.variance).toFixed(2)
            //     }
            // });

            tobacco_shipment.value.tickets.push({
                        barcode: tobacco_shipment.value.barcode,
                        massDispatched: tobacco_shipment.value.massDispatched
                    })

            handleUpdate(tobacco_shipment.value)

            context.refs['barcode'].focus()
            tobacco_shipment.value.barcode = ''
            tobacco_shipment.value.massDispatched = ''

           
        }

        const requestDevice = async () => {
            try {
                const device = await navigator.bluetooth.requestDevice({
                    acceptAllDevices: true
                });
                const server = await device.gatt.connect();

                // Connect to the Bluetooth device
                // const device = await Bluetooth.connect('etJ0wA1bAl4D1hkE6CBMkw==');

                // Send command to request weight data
                // await device.sendCommand('get_weight');

                // Receive data from the device
                const data = await device.receiveData();

                // Process data to extract weight value
                this.weight = this.parseWeightData(data);

                // Close the connection
                await device.close();
            } catch (error) {
                console.error('Failed to connect to device', error);
            }
        }

        const parseWeightData = async () => {

            return JSON.parse(data).weight;
        }
        const changeFormSelection = async (transporter) => {
            
            // fleets.value = transporter.fleets
            // console.log(fleets.value)
            transporter.fleets.forEach(ele => {
               if(ele.isTrailer == 0){
                fleets.value.push(ele)
               }
            });
            
            transporter.fleets.forEach(ele => {
                if(ele.isTrailer == 1){
                    trailers.value.push(ele)
                }
            });
            // console.log(fleets.value)

            await store.dispatch('cromis-tobacco_shipment/transportDriver', { transporter: transporter })
                .then(response => {
                    // console.log(response.data)
                    transporterDrivers.value = response.data

                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSource = async (source) => {
            // console.log(source)
            await store.dispatch('cromis-tobacco_shipment/route_dest', source)
                .then(response => {
                    destinations.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }
        const findRoute = async (source, dest) => {
            console.log(source)
            var route = {
                "source": source,
                "dest": dest
            }
            await store.dispatch('cromis-tobacco_shipment/find_route', { route: route })
                .then(response => {
                    bestRoute.value = response.data.id
                    console.log(response.data)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useTobaccoShipmentsList()

        const viewTickets = (item) => {
            tfDetails.value = item
            tikets.value = item.tickets
            console.log(tfDetails)
            totalWeight.value = 0.000
            tValue.value = 0.000

            for (let index = 0; index < tikets.value.length; index++) {
                totalWeight.value += parseFloat(tikets.value[index].massReceived);
                tValue.value += parseFloat(tikets.value[index].massDispatched);

            }

            myModalTicket.value.show()
        }
        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            console.log(item)


            const trans = transporters.value.find(obj => obj.id === item.drivers.transporter_id);
            changeFormSelection(trans)
            changeSource(item.routes.sourceId)
            tobacco_shipment.value = {
                id: item.id,
                transporter_id: trans,
                truck_id: item.truck_id,
                trailer_id:item.trailer_id,
                route_id: 0,
                driver_id: item.driver_id,
                source_id: item.routes.sourceId,
                destination_id: item.routes.destinationId,
                isShortHaul: true,
                orderNumber: item.orderNumber,
                number: item.number,
                seals:item.seals,
                totalLoad: item.totalLoad,
            }
            myModalTransport.value.show()

        }

        const invokeFleetsForm = () => {
            serverErrors.value = null
            var str = window.location.href;
            var shotHaul;
            str = str.split("/");
            if (str[3] == "tobacco_shipment") {
                shotHaul = false
            } else {
                shotHaul = true
            }
            console.log(str)
            tobacco_shipment.value = {
                id: null,
                truck_id: null,
                trailer_id:null,
                route_id: 0,
                source_id: null,
                destination_id: null,
                isShortHaul: shotHaul,
                transporter_id: null,
                sourceType: 1,
                orderNumber: null,
                licence_number:null,
                barcode: null,
                massDispatched: null,
                loading: true,
                number: null,
                document_number: null,
                totalLoad: 0,
                tickets: [
                {
                    luggage: null,
                    barcode: null,
                    massReceived: null,
                    massDispatched: null,
                    variance: null,
                }
            ]
            }
            myModalTransport.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })


            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (transporter.value.id === null || transporter.value.id === 0)
                handleCreate()
            else
                handleUpdate(sector.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }


        const submitTransports = (bvModalEvt) => {
            console.log(tobacco_shipment.value)
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // handelDriverCreate()

            handleUpdate(tobacco_shipment.value)

        }
        const handleUpdate = async (item) => {
            // tobacco_shipment.value.transporter_id = tobacco_shipment.transporter_id
            saving.value = true
            const valid = await isFormValid()
            item.tickets.shift()
           
            if (!valid) {
                saving.value = false
                return
            }

            let dataLoad = {
                "portal": true,
                "loading": item.loading,
                "tickets": item.tickets
            }

            await store.dispatch('cromis-tobacco_shipment/update', { id: item.id, data: dataLoad })
                .then(response => {

                    console.log(response)
                    tickets.value = response.data.tickets
                    saving.value = false

                    // myModalTransport.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Load has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                })

        }

        const handelDriverCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }
            tobacco_shipment.value.route_id = bestRoute

            console.log(tobacco_shipment.value)


            await store.dispatch('cromis-tobacco_shipment/create', tobacco_shipment.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: 'The transport has been added successfully!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    tobacco_shipment.value = {
                        id: null,
                        license: null,
                        firstName: null,
                        lastName: null,
                        middleName: null,
                        gender: null,
                        transporter_id: null,
                        sourceType: 1,
                        destination_id: null,
                        orderNumber: null,
                        number:null
                    }
                    myModalTransport.value.hide()
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const printPdf = async (tf) => {
          
            console.log(tf)

            window.open(`${ axios.defaults.baseURL }/reports/print_transport?id=${tf.id }`, '_blank')

        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the this TF?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your TF is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const remove = async (id) => {
            await store.dispatch('cromis-tobacco_shipment/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        const confirmDeleteTicket = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the this Ticket?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    removeTicket(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Ticket is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const removeTicket = async (id) => {
            await store.dispatch('cromis-tobacco_shipment/removeTicket', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            transporter,
            tobacco_shipment,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            changeFormSelection,
            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalTransport,
            myModalTicket,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            submitTransports,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            transporters,
            transporterDrivers,
            drivers,
            fleets,
            routes,
            warehouses,
            changeSource,
            findRoute,
            selectedDriver,
            selectedCompany,
            destinations,
            transports,
            markets,
            invokeFleetsForm,
            societies,
            tikets,
            totalWeight,
            tValue,
            viewTickets,
            requestDevice,
            weight,
            device,
            parseWeightData,
            tfDetails,
            confirmDeleteTicket,
            removeTicket,
            trailers,
            addElement,
            removeRow,
            printPdf,
            isItemSelected,
            checkboxToggled,
            toggleCheckAll,
            generateReport,
            validateLocalTf,
            validateBarcode,
            calculateVariance,
            loadingBar,
            handleCheckboxChange,
            loading,
            saving,
            tickets
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>